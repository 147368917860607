'use client';

import { unstableSetRender } from 'antd';
import { createRoot } from 'react-dom/client';

unstableSetRender((node, container) => {
  container._reactRoot ||= createRoot(container);
  const root = container._reactRoot;
  root.render(node);
  return async () => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    root.unmount();
  };
});

import { EntiProvider } from '@/services/context';
// import { AuthProvider } from 'acme-auth';

export function Providers({ children }) {
  return <EntiProvider>{children}</EntiProvider>;
}
